import React, { FC } from "react";
import { Text, Divider, Flex, Box, SystemProps } from "flicket-ui";

import { css } from "~lib/css";

interface Props extends SystemProps {
  title: string;
  description?: string;
  containerProps?: any;
  small?: boolean;
  error?: string;
}

export const FormSection: FC<Props> = ({
  title,
  description,
  children,
  mb,
  containerProps,
  small,
  error,
  ...props
}) => {
  return (
    <Flex
      flexDirection="column"
      css={css({ "&:not(:first-of-type)": { mt: 6 } })}
      {...props}
    >
      <Text fontWeight="heavy" fontSize={small ? 4 : 6} color="N800" mb={2}>
        {title}
      </Text>
      {error && (
        <Text
          variant={"demiBold.M"}
          fontSize={4}
          color="error"
          mb={2}
          whiteSpace="pre-line"
        >
          {error}
        </Text>
      )}
      <Divider />

      <Flex mt={mb || 5} {...containerProps}>
        {description && (
          <Box flex="1 0 33%">
            <Text color="N600" maxWidth="80%" fontSize={4} lineHeight="high">
              {description}
            </Text>
          </Box>
        )}

        <Flex flex="1 0 66%" maxWidth="100%" flexDir="column">
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};
