import React, {
  FC,
  useState,
  useEffect,
  useRef,
  useMemo,
  MouseEvent,
} from "react";
import {
  Box,
  Flex,
  Link,
  PrimaryButton,
  SecondaryButton,
  SystemProps,
  Text,
} from "flicket-ui";
import { get, startCase } from "lodash";
import { useSuperAdmin } from "~hooks/useSuperAdmin";
import styled from "styled-components";

import { useOrganization, useUser } from "~hooks";
import { getUrl } from "~lib";
import { Icon } from "~components/common/Icon";
import { PrintModal } from "~components/orders";
import { Modal } from "../Modal";

type DropdownProps = SystemProps;

export const DropdownToggle = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  background-color: ${(p) => (p.isOpen ? "#7056FA" : "#EBE9F9")};

  border: 1px solid ${(p) => p.theme.colors.P300};
  border-radius: ${(p) => p.theme.radii.full};

  color: ${(p) => (p.isOpen ? "white" : "#7056FA")};
  font-size: ${(p) => p.theme.fontSizes[3]};
  letter-spacing: -0.5px;
  font-weight: ${(p) => p.theme.fontWeights.demiBold};

  cursor: pointer;
`;

export const DropdownContent = styled(Box)`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 48px;
  right: -8px;

  padding: 8px;

  background-color: ${(p) => p.theme.colors.white};

  border-radius: 12px 0 12px 12px;

  box-shadow: ${(p) => p.theme.shadows.hover};

  z-index: ${(p) => p.theme.zIndices.dropdown};

  > a,
  > div {
    display: flex;
    align-items: center;

    min-width: 156px;

    margin-top: 4px;
    margin-bottom: 4px;

    padding: 5px 20px 5px 8px;

    border-radius: 12px;

    color: ${(p) => p.theme.colors.N600};
    font-size: ${(p) => p.theme.fontSizes[3]};
    font-weight: ${(p) => p.theme.fontWeights.demiBold};
    letter-spacing: -0.5px;

    svg path {
      stroke: ${(p) => p.theme.colors.N600};
    }

    &:hover {
      background-color: ${(p) => p.theme.colors.P100};

      color: ${(p) => p.theme.colors.P300};

      svg path {
        stroke: ${(p) => p.theme.colors.P300};
      }
    }

    &:nth-of-type(2) {
      svg path {
        fill: ${(p) => p.theme.colors.N600};
        stroke: initial;
      }

      &:hover {
        svg path {
          fill: ${(p) => p.theme.colors.P300};
          stroke: initial;
        }
      }
    }
  }
`;

const findFirstCapital = (string: string) => {
  const match = /[A-Z]/g.exec(string);

  if (match) {
    return match[0];
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return get(string, "[0]");
};

export const Dropdown: FC<DropdownProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPrintModalOpen, setPrintModalOpen] = useState(false);
  const { logout, user, loggedInOrganizations } = useUser();
  const { isSuperAdmin, isSuperAdminDomain } = useSuperAdmin();
  const dropdownRef = useRef(null);
  const { organization: currentOrganization } = useOrganization();
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = (e) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (!dropdownRef?.current?.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, []);

  const handleOpen = (e: MouseEvent) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const initials = user
    ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${findFirstCapital(user?.firstName)}${findFirstCapital(user?.lastName)}`
    : "";

  // A list of accounts that will be logged out when the user logs out on this subdomain.
  // If the user logs out and they signed in with a linked account, all accounts that were
  // logged in with that account will also be signed out.
  // If the current subdomain org was not logged in with a linked account, then the user
  // will only be logged out of the current subdomain org.
  const accountSlugsToBeLoggedOut = useMemo(() => {
    const currentOrgSlug = startCase(currentOrganization.slug);
    if (!loggedInOrganizations) {
      return [currentOrgSlug];
    }

    const isCurrentOrgLinked = loggedInOrganizations.find(
      (org) => org.slug === currentOrganization.slug
    ).isLinked;
    if (!isCurrentOrgLinked) {
      return [currentOrgSlug];
    }

    const linkedSlugs = loggedInOrganizations
      .filter((org) => org.isLinked)
      .map((org) => startCase(org.slug));

    return linkedSlugs;
  }, [loggedInOrganizations]);

  const handleLogout = async () => {
    if (accountSlugsToBeLoggedOut.length > 1) {
      return setModalOpen(true);
    }

    await logout();
  };

  return (
    <>
      <Box ref={dropdownRef} position="relative" {...props}>
        <DropdownToggle isOpen={isOpen} onClick={handleOpen}>
          {initials}
        </DropdownToggle>
        {isOpen && (
          <DropdownContent>
            {!isSuperAdminDomain && !isSuperAdmin && (
              <Link to={`${getUrl(currentOrganization?.slug)}/account`}>
                <Icon icon="user-account" mr="6/4" fontSize={6} />
                My Account
              </Link>
            )}
            {!isSuperAdminDomain && (
              <a
                onClick={() => {
                  setPrintModalOpen(true);
                }}
              >
                <Icon icon="print" mr="6/4" fontSize={6} />
                Printer settings
              </a>
            )}
            {!isSuperAdminDomain && (
              <Link to={getUrl(currentOrganization?.slug)}>
                <Icon icon="my-tickets" mr="6/4" fontSize={6} />
                Customer side
              </Link>
            )}
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <a href="#" onClick={handleLogout}>
              <Icon icon="logout" mr="6/4" fontSize={6} />
              Sign out
            </a>
          </DropdownContent>
        )}

        <PrintModal
          isOpen={isPrintModalOpen}
          close={() => setPrintModalOpen(false)}
          isConfigure
        />
      </Box>
      <Modal
        header="Sign out"
        isOpen={modalOpen}
        close={() => setModalOpen(false)}
      >
        <div>
          <Text paddingBottom={2}>
            {
              "You're signed in with a linked account. Are you sure you want to sign out of these organizations?"
            }
          </Text>

          {accountSlugsToBeLoggedOut.map((account) => (
            <Text key={account} variant="heavy.L">
              {account}
            </Text>
          ))}
        </div>

        <Flex flexDir={"row"} float="right">
          <SecondaryButton
            marginRight={"3/4"}
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              setModalOpen(false);
              logout().catch((e) => console.error(e));
            }}
          >
            Sign out
          </PrimaryButton>
        </Flex>
      </Modal>
    </>
  );
};
