import { FC, InputHTMLAttributes, ReactNode, useContext } from 'react';
import { Box, Flex, Text, SystemProps } from 'flicket-ui';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';
import { useId } from '~hooks';
import { css } from '~lib/css';
import { RadioContext } from './RadioGroup';

export interface RadioOptionProps
  extends Pick<InputHTMLAttributes<HTMLInputElement>, 'value'>,
    SystemProps {
  label: string | ReactNode;
}

export const RadioOption: FC<RadioOptionProps> = ({
  value,
  label,
  children,
  ...props
}) => {
  const id = useId();
  const { layout, ...radioOptionProps } = useContext(RadioContext);
  const { colors } = useTheme();
  const isHorizontal = layout === 'horizontal';

  return (
    <motion.div
      animate={radioOptionProps.value === value ? 'checked' : 'unchecked'}
      style={
        isHorizontal && {
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: colors.N200,
        }
      }
      variants={
        isHorizontal && {
          checked: { borderColor: colors.P300 },
          unchecked: { borderColor: colors.N200 },
        }
      }
      css={css({
        ...(isHorizontal
          ? {
              boxShadow: 'container',
              borderRadius: 'md',
              p: 1,
              backgroundColor: 'P100',
            }
          : {}),
        ...props,
      })}
    >
      <Box as="label" cursor="pointer" width="100%" htmlFor={String(id)}>
        <Flex
          alignItems="center"
          justifyContent={isHorizontal ? 'space-between' : 'initial'}
        >
          <motion.svg
            css={css({ display: 'inline-block' })}
            height={20}
            width={20}
            viewBox="0 0 20 20"
          >
            <defs>
              <motion.circle
                id="ld"
                fill="transparent"
                cy="10"
                cx="10"
                r="10"
              />
              <clipPath id="clip">
                <use xlinkHref="#ld" />
              </clipPath>
            </defs>
            <g>
              <motion.use
                xlinkHref="#ld"
                stroke={colors.N700}
                variants={{
                  checked: {
                    stroke: colors.P300,
                  },
                  unchecked: {
                    stroke: colors.N700,
                  },
                }}
                strokeWidth="3"
                clipPath="url(#clip)"
              />
            </g>
            <motion.circle
              transition={{
                velocity: 1000,
                type: 'spring',
                stiffness: 20,
                damping: 10,
              }}
              cy="10"
              cx="10"
              r="0"
              fill={colors.P300}
              variants={{
                checked: { r: [0, 7, 6] },
                unchecked: { r: [6, 7, 0] },
              }}
            />
          </motion.svg>
          <Text
            ml={!isHorizontal && 1}
            fontWeight="extraBold"
            color="N600"
            variant="label.S"
          >
            {label}
          </Text>
          {isHorizontal && (
            <Box css={css({ visibilty: 'hidden' })} width={20} />
          )}
        </Flex>
        <Box>{children}</Box>
      </Box>
      <input
        {...radioOptionProps}
        value={value}
        css={css({ display: 'none' })}
        id={String(id)}
        type="radio"
        checked={radioOptionProps.value === value}
      />
    </motion.div>
  );
};
