import React from "react";
import { Text, Flex, SecondaryButton } from "flicket-ui";
import { NamingFieldsOverviewProps } from ".";
import { useNamingFieldOptions } from "./hooks/useNamingFieldOptions";
import { NamingFieldsTag } from "./NamingFieldsTag";

export const NamingFieldsOverview = ({
  fields,
  onClick,
}: NamingFieldsOverviewProps) => {
  const { namingFieldOptions } = useNamingFieldOptions(fields);

  return (
    <>
      <Text color="N600" fontSize={3} fontWeight="extraBold" mb="1/2">
        Naming fields
      </Text>
      <Flex width={1} alignItems="center">
        <Flex
          alignItems="flex-start"
          flexWrap="wrap"
          mr={fields.length ? 2 : 0}
          borderRight="1px"
          borderColor="N200"
          pr={fields.length ? 2 : 0}
        >
          {fields
            ?.filter((f) => f.isRequired)
            .map(({ field }) => (
              <NamingFieldsTag bg="P200" key={field}>
                {
                  Object.entries(namingFieldOptions).find(
                    ([, { field: f }]) => f === field
                  )?.[0]
                }
              </NamingFieldsTag>
            ))}

          {fields
            ?.filter((f) => !f.isRequired)
            .map(({ field }) => (
              <NamingFieldsTag bg="N200" key={field}>
                {
                  Object.entries(namingFieldOptions).find(
                    ([, { field: f }]) => f === field
                  )?.[0]
                }
              </NamingFieldsTag>
            ))}
        </Flex>
        <SecondaryButton onClick={onClick} flexShrink={0}>
          Set naming fields
        </SecondaryButton>
      </Flex>
    </>
  );
};
