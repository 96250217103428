import React from 'react';

import { system, SystemProps } from 'flicket-ui';
import styled from 'styled-components';

const StyledImg = styled.img`
  width: 60px;
  max-height: 40px;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    width: 82px;
  }

  ${system}
`;

export const Logo: React.FC<
  { className?: string; fileName?: string } & SystemProps
> = ({ fileName = 'flicket', ...props }) => {
  return <StyledImg alt="flicket" src={`/static/${fileName}.png`} {...props} />;
};
