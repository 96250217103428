import React, { ReactNode, useRef } from "react";
import { addYears } from "date-fns";
import { InputWrapper } from "flicket-ui";
import Flatpickr, { DateTimePickerProps } from "react-flatpickr";

import { Icon } from "~components";
import Styles, { ClearButton } from "./styles";
import { utcToLocal, localToUtc } from "~lib";
import { IconName } from "../Icon/Icon";

const now = new Date();

const defaultOptions = {
  date: {
    dateFormat: "d - m - Y",
    minDate: now,
    maxDate: addYears(now, 2),
  },
  time: {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H : i",
    time_24hr: true,
    minuteIncrement: 1,
  },
  dateTime: {
    dateFormat: "d - m - Y   H : i",
    minDate: now,
    maxDate: addYears(now, 2),
    enableTime: true,
    time_24hr: true,
    minuteIncrement: 1,
  },
};

interface IDatePicker {
  label?: ReactNode;
  error?: string;
  type?: keyof typeof defaultOptions;
  onChange?: (dateString: string | string[] | null) => void;
  name: string;
  options?: DateTimePickerProps["options"];
  value?: (string | number | Date) | (string | number | Date)[];
  disabled?: boolean;
  placeholder?: string;
  icon?: IconName;
  iconLeft?: boolean;
  allowClear?: boolean;
}

export const DatePicker = ({
  name,
  onChange,
  label,
  type = "date",
  error,
  options,
  value,
  disabled,
  placeholder,
  iconLeft = false,
  icon = "calendar-today",
  allowClear = false,
  ...props
}: IDatePicker) => {
  const flatPickrRef = useRef<Flatpickr>(null);

  // Convert to the Organization timezone before displaying.
  if (value) {
    if (Array.isArray(value)) {
      value = value.map((v) => utcToLocal(v));
    } else {
      value = utcToLocal(value);
    }
  }

  return (
    <>
      <InputWrapper label={label} name={name} error={error} flex="1" {...props}>
        <Styles
          isValid={!error}
          $iconLeft={iconLeft}
          $hideCalendarIconOnHover={allowClear && !!value}
        >
          <Flatpickr
            name={name}
            ref={flatPickrRef}
            placeholder={placeholder}
            options={{
              static: true,
              ...defaultOptions[type],
              ...options,
            }}
            className={`type-${type}`}
            onChange={(val) =>
              onChange(
                val.length > 1
                  ? val.map((date) => localToUtc(date).toISOString())
                  : val?.[0]
                  ? localToUtc(val[0]).toISOString()
                  : undefined
              )
            }
            value={value}
            disabled={disabled}
          />
          {allowClear && value ? (
            <ClearButton
              title="Clear date"
              onClick={(e) => {
                e.preventDefault();
                if (!flatPickrRef?.current?.flatpickr) return;
                flatPickrRef.current.flatpickr.clear();
              }}
            >
              <Icon icon="close" fontSize={4} />
            </ClearButton>
          ) : null}
          <Icon icon={icon} fontSize={5} className="icon" />
        </Styles>
      </InputWrapper>
    </>
  );
};
