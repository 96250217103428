export const CERT = `-----BEGIN CERTIFICATE-----
MIIFDTCCAvegAwIBAgIFMTQzNjIwCwYJKoZIhvcNAQEFMIGYMQswCQYDVQQGEwJV
UzELMAkGA1UECAwCTlkxGzAZBgNVBAoMElFaIEluZHVzdHJpZXMsIExMQzEbMBkG
A1UECwwSUVogSW5kdXN0cmllcywgTExDMRkwFwYDVQQDDBBxemluZHVzdHJpZXMu
Y29tMScwJQYJKoZIhvcNAQkBFhhzdXBwb3J0QHF6aW5kdXN0cmllcy5jb20wHhcN
MjIwNjAyMDQwMDAwWhcNMjMwNjAzMDQwMDAwWjCB1zELMAkGA1UEBgwCTloxETAP
BgNVBAgMCEF1Y2tsYW5kMREwDwYDVQQHDAhBdWNrbGFuZDEUMBIGA1UECgwLRmxp
Y2tldCBMdGQxFDASBgNVBAsMC0ZsaWNrZXQgTHRkMRQwEgYDVQQDDAtGbGlja2V0
IEx0ZDEiMCAGCSqGSIb3DQEJAQwTamltbXlAZmxpY2tldC5jby5uejE8MDoGA1UE
DQwzcmVuZXdhbC1vZi02Y2MyZjI5YTNiNjMwM2Y2NGY1ZTNkMzU2NzVkYzc4MjE0
YjI4NWQxMIIBIDALBgkqhkiG9w0BAQEDggEPADCCAQoCggEBALHEhm3jsWh7YEAG
8XgYtEMzkEZREUX40ciUyQMwa8dJquDMU3dbwaxjs7PYCZxmoaGTHJI9BnLZPhAk
i+Rm/HkEThXrnJqj1kknNGOpPJfF7V91+Q1TvZKyHEaCJW97IwbN2iLywn0RM/ym
WZwwzuPCrfeRIc/AMIPpAMci372a9UDGbIKvkSM5PJFu0dx3CAYhhE46kHpcqxUi
Z6Tr2dpteCWEJ+PNN0GhC6z7lEv09eOz5UUZP8nohjhvJUuL5n2xTL3UQCJwruLr
KvfKDsAaCsiK/NrQTxRLhaLQPkwqHA/XyNW1Ysg1XtdLOYXsLXqESCXFpCfV6kq+
I+a5858CAwEAAaMjMCEwHwYDVR0jBBgwFoAUkKZQt4TUuepf8gWEE3hF6Kl1VFww
CwYJKoZIhvcNAQEFA4ICAQAoXiPI5D/TdaRuH/T31vdfDkFRcBosKcGU2nay2XIx
bj0Kixkkavqqpro/3EFQfGJv9HKQJKIjZ0QemokSqB3ipsMU4p3D/Xjk/wZQhFCX
xv9xGzCOf9RiIpedGQet6OZT4QbqD5nhNSDySn5bZKbZej9uS05u8Xwzp5+Us002
qzdk4kONHGD1PHQff8qVan51579kUVwSa/stU0tH5iuDjGTylhonAX0EijMQqYYu
ggqzcpOvcD0KO2gFnNrO6efyNNbatx3gggqWkQUBMFvybGkf46Orn1zpCh13lf2Q
nUxUwL9CIDqcXxCjSMffLIX/EMztheekXc/cWlOvO6wKMwoC/jpbK3v9of8TmqKC
vKbGTTbn2icJxSCBaqUNopiAgyu91f7jdNwiMMPTqr3bSkwqqvd321ODc2EcjcE0
A4PYq7sNhkjgXBK/7F7N8rFNOe5CIbC7jrREW3CG0sDzKjPWuxVXcndGsKqntj1q
GMsytdbsveyb+KeMsCBfKlBxA4sXv2FYJogxnSpm2jEWUG3YwqblHpAXK8Dg11wZ
ovsjlbCfD8DDc5nqowohCRT6ALjMAG4cBERkOzIaWaH2rryahEI6z8thLiFjIU1M
qNTKjgch93X+f8fdUzcovfAoIpZDrnY6mpR39swQruZdMpv91BT7IQgmd4seMtGq
ww==
-----END CERTIFICATE-----
--START INTERMEDIATE CERT--
-----BEGIN CERTIFICATE-----
MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT
MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ
bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG
A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx
emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow
gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0
cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM
EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1
c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU
iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H
YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM
GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH
EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh
0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX
xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1
9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE
ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD
2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX
6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY
5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ
plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S
BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ
eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k
gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT
x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu
pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw
2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp
rbO7BnjW
-----END CERTIFICATE-----`;

export const INTERMEC_VENDOR_ID = "067e";
export const HONEYWELL_VENDOR_ID = "0c2e";

export const toUTF8Array = (str) => {
  const utf8 = [];
  for (let i = 0; i < str.length; i++) {
    let charcode = str.charCodeAt(i);
    if (charcode < 0x80) utf8.push(charcode);
    else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(
        0xe0 | (charcode >> 12),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      );
    }
    // surrogate pair
    else {
      i++;
      // UTF-16 encodes 0x10000-0x10FFFF by
      // subtracting 0x10000 and splitting the
      // 20 bits of 0x0-0xFFFFF into two halves
      charcode =
        0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
      utf8.push(
        0xf0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3f),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      );
    }
  }
  return utf8;
};
