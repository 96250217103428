import { Flex } from "flicket-ui";
import styled from "styled-components";
import * as yup from "yup";
import { ParamsProps } from "~features/useParams/hooks/useParams";
import { Permission } from "~graphql/sdk";

export const Wrapper = styled(Flex)`
  width: calc(100% + 16px);

  > * {
    margin-right: 16px;
    margin-bottom: 16px;
  }

  > *:not(.date-picker):not(.label) {
    flex: 1;
    min-width: 180px;

    @media (min-width: ${(p) => p.theme.breakpoints.md}) {
      max-width: 160px;
    }
  }
`;

export const schema = yup.object().shape({
  type: yup.string().required(),
  filters: yup.object(),
});

export interface Props {
  close: () => void;
  initialType?: ExportModalType;
  initialParams?: ParamsProps;
}

export const dataOptions = [
  {
    label: "Abandoned Carts",
    value: "cartAbandonment",
    pathname: "order/cartAbandonment",
    permission: Permission.OrderExport,
  },
  {
    label: "Addons",
    value: "addons",
    pathname: "ticket/addons",
    permission: Permission.TicketExport,
  },
  {
    label: "Customers",
    value: "user",
    pathname: "user",
    permission: Permission.CustomerExport,
  },
  {
    label: "Memberships",
    value: "membership",
    pathname: "ticket",
    permission: Permission.MembershipRead,
  },
  {
    label: "Orders",
    value: "order",
    pathname: "order",
    permission: Permission.OrderExport,
  },
  {
    label: "Scan log",
    value: "scans",
    pathname: "scans",
    permission: Permission.TicketExport,
  },
  {
    label: "Tickets",
    value: "ticket",
    pathname: "ticket",
    permission: Permission.TicketExport,
  },
  {
    label: "Transactions",
    value: "transactions",
    pathname: "transactions",
    permission: Permission.OrderExport,
  },
  {
    label: "Waitlist",
    value: "waitlist",
    pathname: "waitlist",
    permission: Permission.WaitlistExport,
  },
];

export const defaultTypes: { pathname: string; value: ExportModalType }[] = [
  { pathname: "/customers", value: "user" },
  { pathname: "/orders", value: "order" },
];

export const modalTypeArr = [
  "cartAbandonment",
  "addons",
  "user",
  "membership",
  "order",
  "scans",
  "ticket",
  "transactions",
  "waitlist",
] as const;

export type ExportModalType = typeof modalTypeArr[number];

export type ExportModalValues = { type: ExportModalType; filters: any };
