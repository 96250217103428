export enum OrganizationFeatures {
  DigitalEvents = "digitalEvents",
  DigitalMemberships = "digitalMemberships",
  VaccinePassport = "vaccinePassport",
  Tags = "tags",
  Points = "points",
  TempPOSOperators = "tempPOSOperators",
  Scout = "scout",
  RenamingFee = "renamingFee",
  Fiji = "fiji",
  Social = "social",
  XeroIntegration = "xeroIntegration",
  Account2account = "account2account",
  Forecast = "forecast",
  RenewalEmail = "renewalEmail",
  GroupLink = "groupLink",
  AutoRenewMemberships = "autoRenewMemberships",
  MembershipTransfers = "membershipTransfers",
  EventSetupGA = "eventSetupGA",
  PinPaymentGateway = "pinPaymentGateway",
  Competitions = "competitions",
}
