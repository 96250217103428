import {
  DialogContent as ReachDialogContent,
  DialogOverlay as ReachDialogOverlay,
} from "@reach/dialog";
import { omit, pick } from "@styled-system/props";
import { Box, system, SystemProps, Text } from "flicket-ui";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";
import styled from "styled-components";

import { Divider, Icon } from "~components";

const MotionOverlay = motion.custom(ReachDialogOverlay);
const MotionDialog = motion.custom(ReachDialogContent);

const DialogOverlay = styled(MotionOverlay)<{ $alignModalTop: boolean }>`
  background: rgba(0, 0, 0, 0.4);
  opacity: 0.75;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: ${(p) => p.theme.zIndices.modal};
  display: flex;
  align-items: ${(p) => (p.$alignModalTop ? " flex-start" : "center")};
  justify-content: center;
  padding: ${(p) => p.theme.space[4]}px;

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    padding: ${(p) => p.theme.space[4]}px ${(p) => p.theme.space[2]}px 92px;
  }
`;

const DialogContent = styled(MotionDialog)<SystemProps>`
  padding: 0;
  width: calc(100vw - 32px);
  margin: 0;
  position: relative;
  background: transparent;
  max-height: 100vh;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    width: 774px;
  }

  && {
    ${system}
  }
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;

  &:hover {
    svg {
      color: ${(p) => p.theme.colors.N800};
    }
  }
`;

export const InHeaderCloseButton = styled.button`
  transition: color 0.2s;

  &:hover {
    svg {
      color: ${(p) => p.theme.colors.N800};
    }
  }
`;

const ContentWrapper = styled(motion.div)<SystemProps>`
  position: relative;
  background: ${(p) => p.theme.colors.white};
  outline: none;
  width: 100%;
  padding: ${(p) => p.theme.space[5]}px;
  border-radius: ${(p) => p.theme.radii.sm};
  box-shadow: ${(p) => p.theme.shadows.sm};
  margin: 64px 0;
  max-height: calc(100vh - 160px);
  overflow-y: auto;

  ${system}
`;

type ModalProps = SystemProps<{
  isOpen: boolean;
  close: (any) => void;
  ariaLabel?: string;
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  hasDivider?: boolean;
  small?: boolean;
  alignModalTop?: boolean;
}>;

export const ModalBaseWrapper = ({
  children,
  ariaLabel,
  isOpen,
  close,
  small = false,
  alignModalTop = false,
  ...props
}: ModalProps) => (
  <AnimatePresence>
    {isOpen && (
      <DialogOverlay
        onDismiss={close}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        $alignModalTop={alignModalTop}
      >
        <DialogContent
          aria-label={ariaLabel || "modal"}
          initial={{ y: -10 }}
          animate={{ y: 0 }}
          exit={{ y: -10 }}
          width={small ? "25%" : undefined}
        >
          <ContentWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            {...pick(props)}
          >
            {children}
          </ContentWrapper>
        </DialogContent>
      </DialogOverlay>
    )}
  </AnimatePresence>
);

export const ModalBase = ({
  children,
  ariaLabel,
  isOpen,
  close,
  header,
  ...props
}: ModalProps) => (
  <ModalBaseWrapper
    close={close}
    isOpen={isOpen}
    ariaLabel={ariaLabel}
    {...props}
  >
    <Box
      d="flex"
      flexDirection={"row-reverse"}
      alignItems="center"
      justifyContent={"space-between"}
    >
      <CloseButton type="button" onClick={close}>
        <Icon icon="close" fontSize={6} />
      </CloseButton>
      {header && (
        <Text fontWeight="heavy" fontSize={6}>
          {header}
        </Text>
      )}
    </Box>
    {children}
  </ModalBaseWrapper>
);

export const Modal = ({
  children,
  footer,
  hasDivider,
  ...props
}: ModalProps) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    <ModalBase {...omit(props)}>
      {hasDivider && <Divider marginY={1} />}

      <Box
        overflowY="auto"
        pt={4}
        pb={footer ? 9 : 0}
        fontSize={4}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        {...pick(props)}
      >
        {children}
      </Box>

      {footer}
    </ModalBase>
  );
};
