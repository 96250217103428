import {
  useCallback,
  useMemo,
  FC,
  HTMLProps,
  CSSProperties,
  KeyboardEvent,
} from "react";
import { Flex, InputWrapper, Text } from "flicket-ui";
import { isHotkey } from "is-hotkey";
import { createEditor, Editor, Node, Range, Transforms, Path } from "slate";
import { withHistory } from "slate-history";
import {
  Editable,
  withReact,
  Slate,
  RenderElementProps,
  RenderLeafProps,
  ReactEditor,
} from "slate-react";
import { MarkButton, BlockButton, toggleMark } from "./Buttons";
import { Toolbar, EditorWrapper, ButtonDivider } from "./components";
import { withLinks, LinkItem, LinkButton } from "./Link";
import {
  DeleteImageButton,
  ImageElement,
  richTextImageAtom,
  withImages,
} from "./Image";
import { useTheme } from "styled-components";
import { withDivider } from "./Divider";
import { RichTextInsertButton, withButton } from "./InsertButton";
import { useAtom } from "jotai";
import {
  CustomFieldElement,
  GroupInsertOptions,
  RichtextEditorSelect,
  withFields,
} from "./Dropdown";
import { useIsMobile, useOrganization } from "~hooks";
import { SuggestedLinkType } from "./InsertModal";
import { Address, BroadcastTransactionalType } from "~graphql/sdk";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
};

type FieldComponent = Omit<
  HTMLProps<HTMLInputElement>,
  "color" | "as" | "ref" | "width" | "onChange"
>;

type InputProps = FieldComponent & {
  value?: string;
  label?: string;
  error?: string;
  onChange?: (val: string) => void;
  isEmail?: boolean;
  isMarketing?: boolean;
  insertDropdownOptions?: GroupInsertOptions[];
  selectEvents?: boolean;
  transactionalType?: BroadcastTransactionalType;
};

const formatAddress = (address: Address) => {
  return `${address.line1}${address.line2 ? ` ${address.line2}` : ""},
  ${address.city}
  ${address.postalCode}`;
};

const editorStyle = (isEmail: boolean): CSSProperties => {
  if (isEmail) {
    return {
      maxWidth: "570px",
      width: "100%",
      alignSelf: "center",
      backgroundColor: "#fff",
      boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.05)",
      padding: "32px 24px",
      margin: "12px 0",
      fontFamily: "Arial, sans-serif",
    };
  } else {
    return {
      overflowY: "auto",
      backgroundColor: "#fff",
      padding: "12px 24px",
    };
  }
};

export const RichText: FC<InputProps> = ({
  name,
  label,
  error,
  onChange,
  value: rawValue,
  // set this to true for email rich text editor
  isEmail = false,
  disabled,
  isMarketing,
  insertDropdownOptions,
  selectEvents = false,
  transactionalType,
  ...props
}) => {
  const { organization } = useOrganization();
  const isMobile = useIsMobile("sm");
  const value = rawValue === null ? undefined : rawValue;
  const formatValue = (value: string): Node[] => {
    try {
      const parsedValue = JSON.parse(value) as Node[];
      return parsedValue;
    } catch (e) {
      return [
        {
          type: "paragraph",
          children: [{ text: value || "" }],
        },
      ];
    }
  };

  const [isImageSelected] = useAtom(richTextImageAtom);

  const editor = useMemo(
    () =>
      withButton(
        withFields(
          withDivider(
            withImages(withLinks(withReact(withHistory(createEditor()))))
          )
        )
      ),
    []
  );

  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} isEmail={isEmail} />,
    [isEmail]
  );

  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );

  const refocusEditor = (editor: ReactEditor, path?: Path) => {
    const { selection } = editor;
    if (path) {
      ReactEditor.focus(editor);
      Transforms.select(editor, path);
      return;
    }
    if (!selection || !selection.anchor || !selection.focus) {
      return;
    }
    ReactEditor.focus(editor);
    Transforms.select(editor, selection.focus);
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey] as string;
        toggleMark(editor, mark);
      }
    }
    // https://github.com/ianstormtaylor/slate/issues/5245#issuecomment-1383752042
    if (event.key === "Enter") {
      const { selection } = editor;
      if (selection && Range.isCollapsed(selection)) {
        const node = editor.children[selection.anchor.path[0]];
        if (node && ["image", "button"].includes(node.type as string)) {
          event.preventDefault();
          const nextIndex = selection.anchor.path[0] + 1;
          const next = editor.children[nextIndex];
          if (next) {
            refocusEditor(editor, [nextIndex]);
            return;
          }
          const emptyElement = {
            type: "paragraph",
            children: [{ text: "" }],
          };
          Transforms.insertNodes(editor, emptyElement, {
            at: [editor.children.length],
          });
          const last = Editor.last(editor, []);
          refocusEditor(editor, last[1]);
          return;
        }
      }
    }
  };

  const renderToolbar = useCallback(() => {
    if (isImageSelected) {
      return (
        <Toolbar>
          <BlockButton format="small" text="Small" />
          <BlockButton format="medium" text="Medium" />
          <BlockButton format="large" text="Large" />
          <ButtonDivider />
          <BlockButton format="left" icon="text-align-left" />
          <BlockButton format="center" icon="text-align-center" />
          <BlockButton format="right" icon="text-align-right" />
          <ButtonDivider />
          <DeleteImageButton />
        </Toolbar>
      );
    } else {
      if (isMobile && isEmail) {
        return (
          <Toolbar flexWrap={"wrap"}>
            <MarkButton format="bold" icon="bold" />
            <MarkButton format="italic" icon="italic" />
            <ButtonDivider />
            <BlockButton format="heading-one" icon="heading-one" />
            <BlockButton format="heading-two" icon="heading-two" />
            <BlockButton format="numbered-list" icon="ol" />
            <BlockButton format="bulleted-list" icon="ul" />
            <ButtonDivider />
            <BlockButton format="left" icon="text-align-left" />
            <BlockButton format="center" icon="text-align-center" />
            <BlockButton format="right" icon="text-align-right" />
            <ButtonDivider />
            <RichtextEditorSelect
              selectEvents={selectEvents}
              insertOptions={insertDropdownOptions}
            />
          </Toolbar>
        );
      } else {
        return (
          <Toolbar>
            <MarkButton format="bold" icon="bold" />
            <MarkButton format="italic" icon="italic" />
            <ButtonDivider />
            <BlockButton format="heading-one" icon="heading-one" />
            <BlockButton format="heading-two" icon="heading-two" />
            <BlockButton format="numbered-list" icon="ol" />
            <BlockButton format="bulleted-list" icon="ul" />
            <ButtonDivider />
            <BlockButton format="left" icon="text-align-left" />
            <BlockButton format="center" icon="text-align-center" />
            <BlockButton format="right" icon="text-align-right" />
            <ButtonDivider />
            {!isEmail && <LinkButton />}
            {isEmail && (
              <RichtextEditorSelect
                selectEvents={selectEvents}
                insertOptions={insertDropdownOptions}
              />
            )}
          </Toolbar>
        );
      }
    }
  }, [isImageSelected, isEmail, isMobile, insertDropdownOptions]);

  return (
    <InputWrapper label={label} name={name} error={error} position="relative">
      <EditorWrapper
        isValid={!error}
        isEmail={isEmail}
        className="editor-wrapper"
      >
        <Slate
          editor={editor}
          value={formatValue(value)}
          onChange={(value) => onChange?.(JSON.stringify(value))}
        >
          {!disabled && renderToolbar()}
          <Editable
            {...props}
            name={name}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            readOnly={disabled}
            spellCheck
            style={editorStyle(isEmail)}
            onKeyDown={(event) => {
              handleOnKeyDown(event);
            }}
            onBlur={() => {
              // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
              editor.blurSelection = editor.selection;
            }}
          />
          {isEmail && (
            <Flex flexDir={"column"} alignItems="center">
              {isMarketing && (
                <>
                  <Text fontSize={2}>
                    This email was sent to [email] as you subscribed to receive
                    marketing.
                  </Text>
                  <Text fontSize={2} textDecoration={"underline"}>
                    Unsubscribe
                  </Text>
                </>
              )}
              {!isMarketing &&
                transactionalType &&
                (transactionalType === BroadcastTransactionalType.Event ? (
                  <Text fontSize={2} textAlign="center">
                    You have received this email because you purchased tickets
                    to [eventName].
                    <br /> If you believe this email is not event related please{" "}
                    <Text as="span" textDecoration={"underline"}>
                      report this email
                    </Text>
                    .
                  </Text>
                ) : (
                  <Text fontSize={2} textAlign="center">
                    You have received this email because you purchased a
                    membership for [membershipName].
                    <br /> If you believe this email is not membership related
                    please{" "}
                    <Text as="span" textDecoration={"underline"}>
                      report this email
                    </Text>
                    .
                  </Text>
                ))}

              <Text textAlign={"center"} fontSize={2} my={2}>
                © {new Date().getFullYear()} {organization?.name}
                <br />
                {formatAddress(organization?.address)}
                <br />
                Powered by{" "}
                <a href="mailto:reporting@flicket.co.nz">
                  <Text as="span" textDecoration={"underline"}>
                    Flicket
                  </Text>
                </a>
                .
              </Text>
            </Flex>
          )}
        </Slate>
      </EditorWrapper>
    </InputWrapper>
  );
};

const Element = ({
  attributes,
  children,
  element,
  isEmail,
}: RenderElementProps & {
  isEmail: boolean;
}) => {
  const theme = useTheme();

  let style: CSSProperties = {};
  if (element.align) {
    if (element.type === "image" && element.size !== "full") {
      style = {
        ...style,
        margin: `auto ${element.align === "right" ? "0" : "auto"} auto ${
          element.align === "left" ? "0" : "auto"
        }`,
      };
    } else {
      style = {
        textAlign: element.align as "left" | "center" | "right",
        ...style,
      };
    }
  }
  if (element.size) {
    style = {
      width:
        element.size === "small"
          ? "33%"
          : element.size === "medium"
          ? "50%"
          : element.size === "large"
          ? "100%"
          : "calc(100% + 90px)",
      ...(element.size === "full" && {
        marginLeft: "-24px",
        marginTop: "-24px",
      }),
      ...style,
    };
  }
  if (element.type === "list-item") {
    style = {
      marginLeft: "15px",
      ...style,
    };
  }

  switch (element.type) {
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case "link":
      return (
        <LinkItem
          attributes={attributes}
          url={element.url as string}
          style={style}
          content={element.content as string}
          isEmail={isEmail}
          suggestedLink={element.suggestedLink as SuggestedLinkType}
          eventId={element.eventId as string}
          releaseId={element.releaseId as string}
        >
          {children}
        </LinkItem>
      );
    case "image":
      return (
        <ImageElement attributes={attributes} element={element} style={style}>
          {children}
        </ImageElement>
      );
    case "divider":
      return (
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: theme.colors.N200,
            margin: `${theme.space[4]}px 0`,
          }}
          {...attributes}
        >
          {children}
        </div>
      );
    case "button":
      return (
        <RichTextInsertButton
          attributes={attributes}
          url={element.url as string}
          content={element.content as string}
          color={element.color as string}
          suggestedLink={element.suggestedLink as SuggestedLinkType}
          eventId={element.eventId as string}
          releaseId={element.releaseId as string}
        >
          {children}
        </RichTextInsertButton>
      );
    case "field":
      return (
        <CustomFieldElement content={element.content as string}>
          {children}
        </CustomFieldElement>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  return <span {...attributes}>{children}</span>;
};
