import React, { ReactNode } from "react";
import { Text, Flex, Box, Link } from "flicket-ui";
import { startsWith, includes, intersection } from "lodash";
import { useRouter } from "next/router";

import styled from "styled-components";

import { Icon, Logo } from "~components";
import {
  useIsMobile,
  usePermissions,
  useUser,
  useSuperAdmin,
  useOrganization,
} from "~hooks";
import {
  Permission,
  Role,
  IntegrationGroup,
  IntegrationType,
  MeQuery,
} from "~graphql/sdk";
import { useIntegrations } from "~graphql";
import { IconName } from "../Icon/Icon";
import { OrganizationFeatures } from "~lib/features";
import { hasRoles } from "~context";

type NavItemsProps = {
  hasPermissions: (requiredPermissions: Permission | Permission[]) => boolean;
  hasFeature: (feature: string) => boolean;
  user: MeQuery["me"];
};

type Item = {
  label: string;
  url: string;
  icon: IconName;
  isActiveCheck?: (url: string) => boolean;
};

type NavLinkProps = {
  icon: IconName;
  url: string;
  isActive: boolean;
  children: ReactNode;
};

const Menu = styled(Flex)`
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 0;

  padding: 12px;

  height: 80px;
  width: 100%;
  background-color: white;

  overflow-y: auto;

  @media (min-width: ${(p) => p.theme.breakpoints.xs}) {
    position: relative;

    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    height: 100%;

    background-color: unset;
  }
`;

const NavItem = styled(Flex)<{ active: boolean }>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 56px;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: ${(p) => p.theme.colors.N600};

  ${(p) =>
    p.active &&
    `
    background-color: ${p.theme.colors.P100};
    color: ${p.theme.colors.P300};
  `}

  @media (min-width: ${(p) => p.theme.breakpoints.xs}) {
    &:hover {
      background-color: ${(p) => p.theme.colors.P100};
      color: ${(p) => p.theme.colors.P300};
    }
  }
`;

const Nav = styled.nav`
  display: flex;

  position: fixed;
  left: 0;
  top: 0;
  z-index: ${(p) => p.theme.zIndices.banner};

  @media (min-width: ${(p) => p.theme.breakpoints.xs}) {
    flex-direction: column;
    align-items: center;

    width: 96px;
    height: 100%;
    background-color: white;

    box-shadow: 0px 0.598509px 2.59354px rgba(0, 0, 0, 0.0525061),
      0px 2.01027px 8.71116px rgba(0, 0, 0, 0.0774939),
      0px 9px 39px rgba(0, 0, 0, 0.13);

    &.is-superadmin {
      padding-top: 80px;
    }
  }
`;

const NavLink = ({ icon, url, isActive, children }: NavLinkProps) => (
  <Link to={url} noHoverEffect width="100%" display="flex">
    <NavItem active={isActive}>
      <Icon icon={icon} mb="1/4" fontSize={24 as any} />
      <Text variant="demiBold.S">{children}</Text>
    </NavItem>
  </Link>
);

const MOBILE_ITEMS = ({ hasPermissions, user }: NavItemsProps) =>
  new Array<Item>(
    hasPermissions(Permission.ReportingFinancial) &&
      !hasRoles(user?.roles, [Role.EventManager]) && {
        label: "Home",
        url: "/",
        icon: "home",
        isActiveCheck: (url) => url === "/",
      },
    hasPermissions(Permission.BroadcastCreate) && {
      label: "Broadcast",
      url: "/broadcast",
      icon: "campaign",
      isActiveCheck: (url) => startsWith(url, "/broadcast"),
    },
    hasPermissions(
      Permission.ReportingFinancial || Permission.ReportingScans
    ) && {
      label: "Reporting",
      url: "/reports",
      icon: "trending_up",
      isActiveCheck: (url) => startsWith(url, "/reports"),
    }
  )?.filter((s) => !!s);

export const NAV_ITEMS = ({
  hasPermissions,
  user,
  hasFeature,
}: NavItemsProps) =>
  new Array<Item>(
    hasPermissions(Permission.ReportingFinancial) &&
      !hasRoles(user?.roles, [Role.EventManager]) && {
        label: "Home",
        url: "/",
        icon: "home",
        isActiveCheck: (url) => url === "/",
      },
    hasPermissions(Permission.EventUpdate) && {
      label: "Events",
      url: "/events",
      icon: "calendar-today",
      isActiveCheck: (url) => startsWith(url, "/events"),
    },
    hasPermissions(Permission.MembershipRead) && {
      label: "Memberships",
      url: "/memberships",
      icon: "membership",
      isActiveCheck: (url) => startsWith(url, "/memberships"),
    },
    hasPermissions(Permission.SeasonRead) && {
      label: "Seasons",
      url: "/seasons",
      icon: "map",
      isActiveCheck: (url) => startsWith(url, "/seasons"),
    },
    hasFeature(OrganizationFeatures.Points) &&
      hasPermissions(Permission.PointsRead) && {
        label: "Points",
        url: "/points",
        icon: "redeem",
        isActiveCheck: (url) => startsWith(url, "/points"),
      },
    hasPermissions(Permission.OrderRead) &&
      !(includes(user?.roles, Role.PosAdmin) && user?.roles.length === 1) && {
        label: "Orders",
        url: "/orders?status=Completed&status=Paid",
        icon: "orders",
        isActiveCheck: (url) => startsWith(url, "/orders"),
      },
    hasPermissions(Permission.CustomerRead) &&
      !user?.roles.includes(Role.SalesOutlet) && {
        label: "Customers",
        url: "/customers",
        icon: "customer",
        isActiveCheck: (url) => startsWith(url, "/customers"),
      },
    hasPermissions(Permission.BroadcastCreate) && {
      label: "Broadcast",
      url: "/broadcast",
      icon: "campaign",
      isActiveCheck: (url) => startsWith(url, "/broadcast"),
    },
    hasPermissions(Permission.ReportingScans) && {
      label: "Reporting",
      url: "/reports",
      icon: "trending_up",
      isActiveCheck: (url) => startsWith(url, "/reports"),
    },
    hasPermissions(Permission.PosCreate) &&
      !hasFeature(OrganizationFeatures.TempPOSOperators) && {
        label: "POS Access",
        url: "/pos/access",
        icon: "lock",
        isActiveCheck: (url) => startsWith(url, "/pos/access"),
      },
    hasPermissions(Permission.PosCreate) && {
      label: "POS Setup",
      url: "/pos",
      icon: "event",
      isActiveCheck: (url) => url === "/pos",
    },
    hasPermissions(Permission.PosCreate) && {
      label: "POS Reporting",
      url: "/pos/reporting",
      icon: "trending_up",
      isActiveCheck: (url) => startsWith(url, "/pos/reporting"),
    },
    hasPermissions(Permission.ReportingFinancial) && {
      label: "Marketing",
      url: "/marketing",
      icon: "puzzle",
      isActiveCheck: (url) => startsWith(url, "/marketing"),
    },
    hasPermissions(Permission.ScoutRead) &&
      hasFeature(OrganizationFeatures.Scout) && {
        label: "Scout",
        url: "/scout",
        icon: "search",
        isActiveCheck: (url) => startsWith(url, "/scout"),
      },
    hasPermissions(Permission.AutomationRead) && {
      label: "Automation",
      url: "/automation",
      icon: "robot",
      isActiveCheck: (url) => startsWith(url, "/automation"),
    }
  )?.filter((s) => !!s);

const SUPERADMIN_ITEMS: Item[] = [
  {
    label: "Accounts",
    url: "/accounts",
    icon: "calendar-today",
    isActiveCheck: (url) => startsWith(url, "/accounts"),
  },
];

export const Navbar = () => {
  const { user } = useUser();
  const router = useRouter();
  const isMobile = useIsMobile("xs");
  const { hasPermissions } = usePermissions();
  const { isSuperAdmin, isSuperAdminDomain } = useSuperAdmin();
  const { hasFeature } = useOrganization();
  const noMarketingRoles = [
    Role.SalesOutlet,
    Role.VenueAdmin,
    Role.EventManager,
  ];
  const marketingEnabled =
    intersection(user?.roles, noMarketingRoles).length > 0
      ? false
      : useIntegrations({
          group: IntegrationGroup.Marketing,
        })?.integrations?.filter((integration) => {
          return integration.type === IntegrationType.MarketingDatorama;
        })?.length > 0;

  const navItems =
    isSuperAdmin && isSuperAdminDomain
      ? SUPERADMIN_ITEMS
      : isMobile
      ? MOBILE_ITEMS({ hasPermissions, hasFeature, user })
      : NAV_ITEMS({ hasPermissions, user, hasFeature });

  return (
    <Nav className={isSuperAdmin && !isSuperAdminDomain ? "is-superadmin" : ""}>
      {!isMobile && (
        <Box mb={4} p={"30px 12px 0" as any}>
          <Link to="/">
            <Logo fileName={isMobile ? "flicket" : "flicket"} />
          </Link>
        </Box>
      )}

      <Menu boxShadow={{ _: "button", md: "none" }} p={"0 12px 30px" as any}>
        {navItems.map(({ url, label, icon, isActiveCheck }) =>
          url.valueOf() === "/marketing" ? (
            marketingEnabled ? (
              <Flex key={url} mb={{ xs: 2 }}>
                <NavLink
                  url={url}
                  icon={icon}
                  isActive={isActiveCheck(router.pathname)}
                >
                  {label}
                </NavLink>
              </Flex>
            ) : null
          ) : (
            <Flex key={url} mb={{ xs: 2 }}>
              <NavLink
                url={url}
                icon={icon}
                isActive={isActiveCheck(router.pathname)}
              >
                {label}
              </NavLink>
            </Flex>
          )
        )}

        {!isMobile &&
          !isSuperAdminDomain &&
          hasPermissions(Permission.OrganizationSettings) && (
            <>
              <Flex width={1} height="1px" bg="N300" mb={1} />
              <Flex flexDir={"column"}>
                <Flex mb={2}>
                  <NavLink
                    url="/settings"
                    icon="settings"
                    isActive={startsWith(router?.pathname, "/settings")}
                  >
                    Settings
                  </NavLink>
                </Flex>
                <Flex>
                  <NavLink
                    url="/help"
                    icon="help"
                    isActive={startsWith(router?.pathname, "/help")}
                  >
                    Help
                  </NavLink>
                </Flex>
              </Flex>
            </>
          )}
      </Menu>
    </Nav>
  );
};
