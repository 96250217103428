import { useRouter } from "next/router";
import useSWR from "swr";
import {
  CreateEventInput,
  EventQuery,
  EventWithListedReleaseQuery,
  UpdateEventInput,
} from "~graphql/sdk";

import { useSDK } from "~hooks";
import { showToast, getError } from "~lib/helpers";

export type Event = EventQuery["event"];

export const useEvent = () => {
  const sdk = useSDK();
  const { query, push, replace } = useRouter();
  const id = query?.id as string;

  const { data: event, error, ...methods } = useSWR(
    id && id !== "new" ? ["event", id] : null,
    async (_, id: string) => sdk.event({ id }).then((res) => res.event),
    {
      revalidateOnFocus: false,
    }
  );

  const { data: releases, error: releaseError } = useSWR(
    event ? ["releases", event.id] : null,
    async (_, id: string) =>
      sdk.releases({ event: id }).then((res) => res.releases),
    {
      revalidateOnFocus: false,
    }
  );

  const createEvent = async (input: CreateEventInput) => {
    try {
      const res = await sdk.createEvent({ input });

      showToast("Event created", "success");
      await replace("/events/[id]", `/events/${res.createEvent.id}`);
    } catch (err) {
      showToast(getError(err, "graphQL"), "error");
    }
  };

  const updateEvent = async (
    input: UpdateEventInput,
    sendTicketViewableEmails?: boolean
  ) => {
    try {
      await sdk.updateEvent({
        id,
        input,
        sendTicketViewableEmails,
      });

      await methods.mutate(undefined, true);
      showToast("Successfully updated event");
    } catch (err) {
      showToast(getError(err, "graphQL"), "error");
    }
  };

  const duplicateEvent = () => {
    sdk
      .duplicateEvent({ id })
      .then((res) => {
        showToast("Event duplicated!", "success");
        push(
          "/events/[id]",
          `/events/${res.duplicateEvent}?tab=general`
        ).catch((err) => showToast(getError(err, "graphQL"), "error"));
      })
      .catch((err) => showToast(getError(err, "graphQL"), "error"));
  };

  return {
    ...methods,
    error,
    event,
    releases,
    createEvent,
    updateEvent,
    duplicateEvent,
  };
};
