import { Box } from "flicket-ui";
import { startCase } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "~components";
import { useOrganization, useUser } from "~hooks";
import { getUrl } from "~lib";
import { Icon } from "../Icon";
import { DropdownContent } from "./Dropdown";

const MenuButton = ({
  onClick,
}: {
  isOpen: boolean;
  onClick: (e: React.MouseEvent) => void;
}) => (
  <Button
    color="S300"
    backgroundColor={"P100"}
    onClick={onClick}
    marginRight="6/4"
  >
    My Organizations
  </Button>
);

export const OrganizationMenu = (props) => {
  const { organization: currentOrganization } = useOrganization();
  const { loggedInOrganizations } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { pathname } = useRouter();
  // Hide organization menu on POS pages
  const hideMenu = pathname.startsWith("/pos");

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, []);

  const handleClick = (event: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (!dropdownRef?.current?.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  if (!loggedInOrganizations || loggedInOrganizations?.length < 2) return null;

  // Excludes current org
  const filteredOrgs = loggedInOrganizations?.filter(
    (org) => org.slug !== currentOrganization.slug
  );

  const firstThreeOrgs = filteredOrgs.slice(0, 3);
  const remainingOrgsCount = filteredOrgs.slice(3).length;

  if (hideMenu) {
    return null;
  }

  return (
    <Box ref={dropdownRef} position="relative" {...props}>
      <MenuButton isOpen={isOpen} onClick={handleOpen} />
      {isOpen && (
        <DropdownContent>
          {firstThreeOrgs.map(({ slug }, i) => (
            <a
              key={i}
              href={getUrl(slug, true)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon icon="export" mr="6/4" fontSize={1} />
              {startCase(slug)}
            </a>
          ))}
          {remainingOrgsCount > 0 && (
            <a
              key={"remaining"}
              href={`${process.env.LOGIN_PORTAL_URL}/accounts`}
              rel="noopener noreferrer"
              target="_blank"
            >
              View {remainingOrgsCount} more
            </a>
          )}
        </DropdownContent>
      )}
    </Box>
  );
};
