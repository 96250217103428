import {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  ReactNode,
} from "react";
import { Flex, Box, SystemProps, system } from "flicket-ui";
import styled from "styled-components";

type BoxProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  SystemProps & {
    children?: ReactNode;
  };

const StyledMenu = styled(Box)`
  & > * {
    display: inline-flex;
    align-items: flex-end;
  }
  & > * + * {
    margin-left: 15px;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    & > * + * {
      margin-top: 5px;
    }
  }
`;

export const Menu = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => (
    <StyledMenu {...props} ref={ref}>
      {children}
    </StyledMenu>
  )
);
Menu.displayName = "Menu";

const StyledToolbar = styled(Menu)`
  display: flex;
  align-items: flex-end;
  position: sticky;
  top: 0;
  padding: 12px 24px;
  background-color: #fff;
  border-radius: 6px;
  z-index: 100;
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    height: 1px;
    width: 100%;
    background-color: ${(p) => p.theme.colors.N200};
  }
  ${system}
`;

export const Toolbar = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => (
    <StyledToolbar {...props} ref={ref}>
      {children}
    </StyledToolbar>
  )
);
Toolbar.displayName = "Toolbar";

export const ButtonDivider = styled(Flex)`
  width: 1px;
  background-color: ${(p) => p.theme.colors.N200};
  height: 22px;
  margin-left: ${(p) => p.theme.space[1]}px;
  margin-right: -${(p) => p.theme.space[1]}px;
`;

export const EditorWrapper = styled(Box)<{
  isValid?: boolean;
  isEmail: boolean;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.N100};
  width: 100%;
  ${(p) => (p.isEmail ? `min-height: 450px;` : `height:350px;`)}
  border: 1px solid ${(p) => p.theme.colors[p.isValid ? "N200" : "error"]};
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);

  h1 {
    font-size: 40px !important;
  }

  h2 {
    font-size: 24px !important;
  }

  &:focus-within {
    border-color: ${(p) => p.theme.colors[p.isValid ? "N500" : "error"]};
  }

  [data-slate-node="value"] {
    flex: 1;
  }

  .react-select__control {
    .react-select__dropdown-indicator svg {
      color: ${(p) => p.theme.colors.N500} !important;
    }

    .react-select__value-container {
      padding-top: ${(p) => (p.isEmail ? "0 !important" : "auto")};
    }
  }
`;

type ButtonProps = DetailedHTMLProps<
  HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  SystemProps & {
    active?: boolean;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ active, ...props }, ref) => (
    <Flex
      as="button"
      type="button"
      alignItems="center"
      justifyContent="center"
      ref={ref}
      padding={"1px" as any}
      backgroundColor={active ? "P100" : "transparent"}
      borderRadius={"xs"}
      color={active ? "N800" : "N500"}
      {...props}
    />
  )
);
Button.displayName = "Button";
